import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import HttpsRedirect from 'react-https-redirect';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from './authConfig'
import { ToastContextProvider } from "./contexts/ToastContext";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ToastContextProvider>
            <App />
          </ToastContextProvider>
        </Provider>
      </MsalProvider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
