import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CRow,
  CSpinner
} from  '@coreui/react-pro'
import {registerLocale, setDefaultLocale } from "react-datepicker";
import da from 'date-fns/locale/da';
import en from 'date-fns/locale/en-US';
setDefaultLocale('en-US',en);
registerLocale('da', da);
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Register = React.lazy(() => import('./views/pages/register/Register'));

class App extends Component {
  constructor(props) {
    super(props);
    this.setState({ 
      isAuthenticated: false,
      tenantCompanyName : '',
      tenantIdentifier : '',
      tenantIsRequesterAdministrator:false,
      userIsActive :false,
      redirectTo403 : false,
      redirectTo500 : false
    });
  }
  render() {
    function ErrorComponent({error}) {
      return <div className="c-app c-default-layout flex-row align-items-center">
      <div className="hidden"></div>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" lg="7" xl="6">
            <CCard className="mx-4">
              <CCardBody className="p-4"><CSpinner /> Huston we got a problem! 
              {error}
              </CCardBody>
              <CCardFooter className="p-4">
                {/* <CRow>
                  <CCol xs="12" sm="6">
                    <CButton className="btn-facebook mb-1" block><span>facebook</span></CButton>
                  </CCol>
                  <CCol xs="12" sm="6">
                    <CButton className="btn-twitter mb-1" block><span>twitter</span></CButton>
                  </CCol>
                </CRow> */}
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>;
  }

  function LoadingComponent() {
      return <div className="c-app c-default-layout flex-row align-items-center">
      <div className="hidden"></div>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="9" lg="7" xl="6">
            <CCard className="mx-4">
              <CCardBody className="p-4"><CSpinner /> Preparing your profile.. Please wait! 
                    
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  }
    return (

        
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/register" name="Register Page" element={<Register />}  />
                <Route exact path="/401" name="Page 401" element={<Page401 />} />
                <Route exact path="/403" name="Page 403" element={<Page403 />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route path="*" name="Home" element={<MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect}
          errorComponent={ErrorComponent} 
          loadingComponent={LoadingComponent}
        ><DefaultLayout /> </MsalAuthenticationTemplate>} />
              </Routes>
            </Suspense>
          </HashRouter>
       
    )
  }
}

export default App
